<template>
  <div class="encryption">
    <v-list
      ref="list"
      :isAdd="true"
      :isOperateColumn="false"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :extraParams="extraParams"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入" @click="toAdd"></v-button>
        <v-button text="导出" @click="toAdd"></v-button> -->
        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <v-input
          label="名称"
          clearable
          @change="$refs.list.search()"
          v-model="searchParam.title"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { totalSecurityUrl } from "./api.js";

export default {
  name: "encryption",
  data() {
    return {
      wordsOptions: [],
      searchParam: {
        title: null,
      },
      headers: [
        {
          prop: "code",
          label: "编码",
        },
        {
          prop: "title",
          label: "名称",
        },
        {
          prop: "content",
          label: "描述",
        },
      ],
      extraParams: {
        type: 12,
      },
      tableUrl: totalSecurityUrl,
    };
  },
  mounted() {
    this.getReasons();
  },
  methods: {
    batchSend(id) {
      console.log(id, "+++");
      let ids = id.ids.join(",");
      this.deleteWord(ids);
    },
    getReasons() {
      //   let params = {
      //     tenantId: this.$store.state.app.userInfo.tenantId,
      //   };
      //   this.$axios.get(`${reasonsListUrl}`, params).then((res) => {
      //     if (res.code === 200) {
      //       if (res.data && res.data.sensitiveWordReasonList) {
      //         res.data.sensitiveWordReasonList.forEach((ele) => {
      //           let obj = {};
      //           obj.label = ele.dictKey;
      //           obj.value = ele.dictValue;
      //           this.wordsOptions.push(obj);
      //         });
      //       }
      //     }
      //   });
    },
    deleteWord(ids) {},
    toAdd() {
      this.$router.push({
        name: "addSensitiveWords",
      });
    },
    toEdit(data) {
      this.$router.push({
        name: "accountAdd",
        query: { id: data.id, communityId: data.communityId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.encryption {
  box-sizing: border-box;
  height: 100%;
}
</style>
